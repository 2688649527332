import React, { useState } from "react"
import "../Card/listingcard.scss"
import VizSensor from "react-visibility-sensor"
import { Link } from "gatsby"
import PreLoader from "./preloader.png"
const ListingCardNew = props => {
  const [imgViz, setimgViz] = useState(false)
  const [imgVizdisplay, setimgVizdisplay] = useState(false)
  const url = `https://www.gofloaters.com/app/#/home/explore/bookingrequest/${props.spaceId}`
  var divStyleLoad = {
    height: "200px",
    backgroundImage: "url(" + PreLoader + ")",
    backgroundSize: "cover",
    WebkitTransition: "all", // note the capital 'W' here
    msTransition: "all", // 'ms' is the only lowercase vendor prefix
    borderRadius: "15px 15px 0 0",
  }
  var divStyle = {
    height: "200px",
    backgroundImage: "url(" + props.listingImg + ")",
    backgroundSize: "cover",
    WebkitTransition: "all", // note the capital 'W' here
    msTransition: "all", // 'ms' is the only lowercase vendor prefix
    borderRadius: "15px 15px 0 0",
  }

  var str = ""
  const HourPrice = () => {
    return (
      <span>
        ₹ <b>{props.priceHr}</b> per hour<br></br>
      </span>
    )
  }

  const MonthlyPrice = () => {
    return (
      <span>
        ₹ <b>{props.priceMonth}</b> per month<br></br>
      </span>
    )
  }
  const DailyPrice = () => {
    return (
      <span>
        ₹ <b>{props.priceDay}</b> per day
        <br></br>
      </span>
    )
  }
  const DailyFree = () => {
    return (
      <span>
        Daily: NA
        <br></br>
      </span>
    )
  }
  function decimalAdjust(type, value, exp) {
    // If the exp is undefined or zero...
    if (typeof exp === "undefined" || +exp === 0) {
      return Math[type](value)
    }
    value = +value
    exp = +exp
    // If the value is not a number or the exp is not an integer...
    if (isNaN(value) || !(typeof exp === "number" && exp % 1 === 0)) {
      return NaN
    }
    // Shift
    value = value.toString().split("e")
    value = Math[type](+(value[0] + "e" + (value[1] ? +value[1] - exp : -exp)))
    // Shift back
    value = value.toString().split("e")
    return +(value[0] + "e" + (value[1] ? +value[1] + exp : exp))
  }
  const ceil10 = (value, exp) => decimalAdjust("ceil", value, exp)
  return (
    <div className={"col-md-4 listingCardPadding " + str}>
      <a href={props.Slug + "/"} target="_blank">
        <div
          className="listingCard"
          title={props.gftitle + " - " + props.OriginalName}
        >
          <div className="listingimages">
            <VizSensor
              partialVisibility
              onChange={isVisible => {
                setimgViz(isVisible)
                if (!imgVizdisplay) {
                  setimgVizdisplay(isVisible)
                }
              }}
            >
              <div
                style={imgVizdisplay ? divStyle : divStyleLoad}
                className={props.online ? "" : "inactiveImg"}
              >
                {props.hasCovidSafeBadge ? (
                  <Link className="verifiedSpaceNew" to="/covid-safety-pledge/">
                    <img
                      src="https://assets.gofloaters.com/hearttick.svg"
                      className="verfiedBadge"
                      title="Verified Spaces"
                      alt="Verified Spaces"
                    ></img>
                    <p>Verified Safe</p>
                  </Link>
                ) : (
                  ""
                )}
              </div>
            </VizSensor>
          </div>
          <div className="listingDetail">
            <div className="row aligner">
              <div className="col-9">
                {props.spacetype != "Cafe" ? (
                  <h2 title={props.gftitle + " - " + props.OriginalName}>
                    {props.gftitle} - {props.OriginalName}{" "}
                  </h2>
                ) : (
                  ""
                )}
                {props.spacetype === "Cafe" ? (
                  <h2 title={props.gftitle - props.OriginalName}>
                    {props.title}{" "}
                  </h2>
                ) : (
                  ""
                )}
              </div>
              <div className="col-3 text-right">
                {props.Rating ? (
                  <p>
                    <i className="fa fa-star star"></i> {props.Rating}
                  </p>
                ) : (
                  ""
                )}
              </div>

              <div className="col-12">
                <p className="spaceaddress">
                  {" "}
                  {props.spacetype != "Cafe"
                    ? props.spaceDisplayName + " | "
                    : ""}{" "}
                  {props.spaceAddress}
                </p>
              </div>
            </div>

            <div className="row aligner">
              <div className="col-12">
                <p className="spaceDescription">
                  {" "}
                  {props.spaceDesc ? props.spaceDesc : ""}
                </p>
              </div>
            </div>
            <hr />
            <div className="row aligner minheight">
              <div className="col-6">
                <p style={{ fontSize: "12px", color: "#515151" }}>
                  Starting from
                </p>
                <p>
                  {props.hourPassAvailable && props.priceHr !== " " ? (
                    <HourPrice />
                  ) : (
                    ""
                  )}
                  {props.dayPassAvailable && props.priceDay !== " " ? (
                    <DailyPrice />
                  ) : (
                    ""
                  )}
                </p>
              </div>
              <div className="col-6 text-right">
                {props.online ? (
                  <a
                    href={props.Slug + "/"}
                    target="_blank"
                    className="LinkButtonCard"
                  >
                    Explore <i class="fa fa-arrow-right" aria-hidden="true"></i>
                  </a>
                ) : (
                  <a
                    href={props.Slug + "/"}
                    className="LinkButtonCard inactive"
                    target="_blank"
                  >
                    Explore <i class="fa fa-arrow-right" aria-hidden="true"></i>
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </a>
    </div>
  )
}
export default ListingCardNew
